import React, {useEffect, useState} from 'react';
import { Link, navigate } from 'gatsby';
import {Link as GoLink} from 'react-scroll/modules';
import axios from 'axios';
import Layout from '../../components/layout';
import Seo from '../../components/seo';
import ListBrand from '../../components/list-brand';
import ListLink from '../../components/list-link';
import HtmlEscape from '../../components/EscapeHtml';
import Image from '../../components/Image';

const DailyUsePage = (props) => {
  const { location } = props;
  const [pageId, setPageId] = useState('');
  const [content, setContent] = useState('');
  const [pageTitle, setPageTitle] = useState('');
  const [pageTitleEn, setPageTitleEn] = useState('');
  const [pageCategory, setPageCategory] = useState('');
  // Ajax設定
  const configJson = {
    method: 'get',
    url: `/update/accessory/${pageId}/setting.json`,
    headers: {
      Accept: 'application/json',
    },
    data: '',
  };
  const configText = {
    method: 'get',
    url: `/update/accessory/${pageId}/index.html`,
    headers: {
      Accept: 'text/html',
    },
    data: '',
  };
  // Ajax実行
  const dataFetch = async () => {
    axios(configJson)
    .then((response) => {
      setPageTitle(response.data.title);
      setPageTitleEn(response.data.en);
      setPageCategory(response.data.category);
    })
    .catch(() => {
      // console.log(error);
    });
    axios(configText)
    .then((response) => {
      setContent(response.data);
    })
    .catch(() => {
      // console.log(error);
    });
  };
  // Event
  useEffect(() => {
    setPageId(location.search.split('?id=')[1]);
    if (pageId) {
      dataFetch();
    }
    if (typeof pageId === 'undefined') {
      navigate('/404');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageId, location.search.split('?id=')[1]]);
  return (
      <Layout page="accessory-page">
        <Seo title="ACCESSORY"/>
        <section className="page-head">
          <ListBrand/>
          <ListLink/>
          <h2 className="main-title"><span>{pageTitleEn}</span></h2>
        </section>
        <section className="page-content flex-layout">
          <div className="main-content">
            <div className="content-block">
              <h3 className="sub-title"><span>{pageTitle}</span></h3>
            </div>
            {HtmlEscape(content)}
            <div id="pdf" className="content-block">
              <a className="btn pdf full mt-12" href="/update/pdf/accessory.pdf" target="_blank">
                PDFカタログ ダウンロード
              </a>
            </div>
          </div>
          <div className="side-list">
            <ul className="sticky">
              {pageCategory
                  ? pageCategory.map((category) => (
                      <li key={category.link}>
                        <GoLink to={category.link} spy smooth duration={800}>
                          {category.name}
                        </GoLink>
                      </li>
                  ))
                  : null}
              <li>
                <GoLink className="c-attention" to="pdf" spy smooth duration={800}>
                  PDFカタログ
                </GoLink>
              </li>
            </ul>
          </div>
        </section>
        <section className="bread">
          <ul>
            <li>
              <Link to="/">
                <Image filename="ico/home.png" />
              </Link>
            </li>
            <li>
              <Link to="/accessory/">
                アクセサリ一覧
              </Link>
            </li>
            <li>{pageTitle}</li>
          </ul>
        </section>
      </Layout>
  );
};

export default DailyUsePage;
